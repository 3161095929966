export const FEATURE_FLAGS = {
  PIM_FEATURE_FLAGS: 'PIM_FEATURE_FLAGS',
  ITEMS_BULK_EXPORT_NOTIFICATION: 'ui-item-bulk-export',
  I18N_LOCALIZATION: 'ui-i18n',
  RBAC: 'ui-rbac',
  RBAC_VIEWER_MODE: 'ui-rbac-viewer-mode',
  STG01_OVERRIDE: 'ui-stg01',
  UI_V3: 'ui-v3',
  DRAFT_STATE: 'ui-draft-state',
  WAM: 'ui-wam',
  V3_TENANT: 'pim-v3-tenant',
  DS4: 'ui-ds4',
  UPDATED_NAVIGATION: 'updated-navigation',
  HIDE_INTERNAL_LEFT_NAV: 'hide-internal-left-nav',
  COLLECTION_REFACTORED: 'collection-refactored',
  UI_MULTICHANNEL: 'ui-multichannel',
  TEST_ENABLE_ATTRIBUTE_MAPPING_DROPDOWN:
    'ui-test-enable-attribute-mapping-dropdown',
  CONVO_AI: 'convo-ai',
  PIM_UI_BULK_PUBLISH_UNPUBLISH: 'pim-ui-bulk-publish-unpublish',
  PRODUCT_DESCRIPTION_GENERATOR: 'product-description-generator',
  CANCEL_JOB_V3: 'pim-v3-cancel-job',
  BULK_UPDATE_NEW_UX: 'bulk-update-new-ux',
  ENHANCED_ATTRIBUTE_AND_VALUES_FILTER: 'Enhanced-Attribute-and-Values-Filter',
  UI_DETAILS_PAGE_INLINE_EDIT: 'ui-details-page-inline-edit',
}

export const featureFlagDomainMapping = {
  dev02: 'dev',
  stg02: 'stage',
  sandbox: 'sandbox',
  prod01: 'prod',
}

export const featureFlagEnvMapping = {
  dev02: 'development',
  stg02: 'staging',
  sandbox: 'uat',
  prod01: 'production',
}

export const CHUNK_SIZE = 2
export const DEFAULT_TIMEOUT = 5000
export const MAX_LOCALE_PILLS_TO_DISPLAY = 2
export const ENTER_KEY = 13
export const MAX_CHANNEL_PILLS_TO_DISPLAY = 2
